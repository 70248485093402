import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { subscribe, unsubscribe } from "../../Services/pubSub";

const ErrorAlert = () => {
  const [errors, setErrors] = useState([]);

  const [t] = useTranslation();

  useEffect(() => {
    subscribe("apiError", handleErrors);

    // Clean up subscription on component unmount
    return () => {
      unsubscribe("apiError", handleErrors);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleErrors = (newError) => {
    // passing in old error values prevents closure over stale values
    setErrors((previousErrors) => [...previousErrors, newError]);
  };

  const removeError = (index) => {
    setErrors(errors.toSpliced(index, 1));
  };

  return (
    <div className="fixed-bottom">
      {errors.map((error, index) => (
        <div
          className="alert alert-danger alert-dismissible m-4"
          role="alert"
          key={index}
        >
          <div>
            {t("common.error")}: {error}
          </div>
          <button
            type="button"
            className="btn-close"
            aria-label={t("common.close")}
            onClick={() => removeError(index)}
          ></button>
        </div>
      ))}
    </div>
  );
};

export default ErrorAlert;
