import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { FEATURES } from "../../Auth/AuthCheck";
import NotAuthorized from "../../Auth/NotAuthorized";
import { useAccessGroups } from "../../Common/Hooks/useAccessGroups";
import { useDpodUser } from "../../Common/Hooks/useDpodUser";
import { findAuthorizedFeature } from "../../Common/Utilities/helperFunctions";
import "./Landing.css";

// Feature constants
const { PATHOLOGY, REQUISITIONS, USERS } = FEATURES;

const Landing = () => {
  const navigate = useNavigate();
  const authState = useDpodUser();
  const { accessGroups } = useAccessGroups();

  // Translation hook
  const [t] = useTranslation();

  const authorizedFeatures = authState?.dpodUser?.access;

  // Auto rerouting logic for the users not having access to both Pathology-Images and Requisitions
  if (Array.isArray(authorizedFeatures)) {
    const pathologyFeature = findAuthorizedFeature(
      authorizedFeatures,
      PATHOLOGY
    );
    const requisitionsFeature = findAuthorizedFeature(
      authorizedFeatures,
      REQUISITIONS
    );
    const usersFeature = findAuthorizedFeature(authorizedFeatures, USERS);

    if (pathologyFeature && !requisitionsFeature) {
      return <Navigate to="/mypathology" />;
    } else if (!pathologyFeature && requisitionsFeature) {
      return <Navigate to="/requisitions" />;
    } else if (!pathologyFeature && !requisitionsFeature && usersFeature) {
      return <Navigate to="/users" />;
    } else if (!pathologyFeature && !requisitionsFeature) {
      return <NotAuthorized />;
    }
  } else {
    return <NotAuthorized />;
  }

  return (
    <div className="container-fluid p-0 landing">
      <div className="text-center landing-bg d-flex align-items-center justify-content-center">
        <div className="d-flex">
          <button
            className="card border-0 nav-cards cursor-pointer btn p-0"
            onClick={() => navigate("/requisitions")}
          >
            <div className="card-body d-flex align-items-center justify-content-center card-bg w-100">
              <h2 className="card-text">{t("navigation.requisitions")}</h2>
            </div>
          </button>
          <button
            className="card border-0 nav-cards cursor-pointer btn p-0"
            onClick={() => navigate("/mypathology")}
          >
            <div className="card-body d-flex align-items-center justify-content-center card-bg w-100">
              <h2 className="card-text">{t("navigation.pathologyImages")}</h2>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landing;
