/* 
TODO: Commented out code to be removed
*/
/* export const ORDER_ADMIN = "ORDER_ADMIN";
export const PATHOLOGY_USER = "PATHOLOGY_USER";
export const IRM_SCANNER_OPERATOR = "IRM_SCANNER_OPERATOR";
export const IRM_ORDER_FULFILLMENT_ADMIN = "IRM_ORDER_FULFILLMENT_ADMIN";

// Link names to identifiers

const roles = [
  { roleName: "Order Admin", roleIdentifier: ORDER_ADMIN },
  { roleName: "Pathology User", roleIdentifier: PATHOLOGY_USER },
  { roleName: "Scanner Operator", roleIdentifier: IRM_SCANNER_OPERATOR },
  {
    roleName: "Order Fulfillment Admin",
    roleIdentifier: IRM_ORDER_FULFILLMENT_ADMIN,
  },
];
 */

//const getRoleForId = roleId => roles[roleId - 1] // roleId is between 1 and roles.length

//function roleMatchesIdentifier(role, roleIdentifier) {
//    if (role.roleIdentifier === roleIdentifier) {
//        console.log('True')
//        return true
//    }
//    console.log('Get Role: ' + getRoleForId(roleId).roleIdentifier + ' Role Identifier: ' + roleIdentifier);
//    return role.roleIdentifier === roleIdentifier
//}
//const roleIdMatchesIdentifier = (roleId, roleIdentifier) =>
//    getRoleForId(roleId).roleIdentifier === roleIdentifier

/* const authUserHasRole = (
  roleIdentifier // Do some of the auth user's roles match the identifier?
) => {
  for (let key in roles) {
    if (roleIdentifier === roles[key].roleIdentifier) {
      return true;
    }
  }
  return false;
  //if (typeof global.authUser === 'undefined' || global.authUser === null) {
  //    return false
  // }
  // const authUser = global.authUser
  // global.authUser.roles.some(roleId => roleIdMatchesIdentifier(roleId, roleIdentifier))
}; */

/* export const authUserIsOrderAdmin = () => authUserHasRole(ORDER_ADMIN);
export const authUserIsPathologyUser = () => authUserHasRole(PATHOLOGY_USER);
export const authUserIsIrmScannerOperator = () =>
  authUserHasRole(IRM_SCANNER_OPERATOR);
export const authUserIsIrmOrderFulfillmentOperator = () =>
  authUserHasRole(IRM_ORDER_FULFILLMENT_ADMIN);

export const authUserIsOrderAdminAndPathologyUser = () =>
  authUserIsOrderAdmin && authUserIsPathologyUser;
export const authUserIsScannerOperatoryAndOrderFulfillmentAdmin = () =>
  authUserIsIrmScannerOperator && authUserIsIrmOrderFulfillmentOperator; */

export const FEATURES = {
  PATHOLOGY: "Pathology",
  REQUISITIONS: "Requisitions",
  ORDERS: "Orders",
  USERS: "Users",
  COMMON: "Common",
};
