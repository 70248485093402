// import { useOktaAuth } from "@okta/okta-react";
import { useLayoutEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FEATURES } from "../../Auth/AuthCheck";
import { useDpodUser } from "../Hooks/useDpodUser";
import { API_PATHS } from "../Utilities/constants";
import {
  findAuthorizedFeature,
  isCapabilityAllowed,
} from "../Utilities/helperFunctions";
import CartIcon from "./CartIcon";

import { useTranslation } from "react-i18next";
import { ReactComponent as LanguageIcon } from "../../Assets/Images/language.svg";
import logo from "../../Assets/Images/logo-digital-pathology.svg";
import IdleTimerAlert from "../Modals/IdleTimerAlert";
import "./NavMenu.css";

// Feature constants
const { PATHOLOGY, REQUISITIONS, USERS } = FEATURES;
const { ORDERS_SEARCH, REQUISITION_MY_ITEM, MY_PATHOLOGY_SEARCH, GROUPS } =
  API_PATHS;

const locales = {
  en: { title: "common.englishLang" },
  fr: { title: "common.frenchLang" },
};

const NavMenu = () => {
  // State Variables
  const [pathologyFeature, setPathologyFeature] = useState();
  const [requisitionsFeature, setRequisitionsFeature] = useState();
  const [usersFeature, setUsersFeature] = useState();
  const [showModal, setShowModal] = useState(false);
  const [language, setLanguage] = useState("");

  // Custom Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const authState = useDpodUser();

  // Translation hook
  const { t, i18n } = useTranslation();

  const { access, firstName, lastName } = authState?.dpodUser || {};

  const activeNavItem = ({ isActive }) =>
    `nav-link d-flex justify-content-center align-items-center ${
      isActive && "active"
    }`;

  const loggingOut = () => {
    navigate("/logout");
  };

  const changeLanguage = (locale) => {
    setLanguage(locales[locale].title);
    i18n.changeLanguage(locale);
    localStorage.setItem("user-preferred-language", locale);
  };

  // Hooks
  useLayoutEffect(() => {
    const userLanguage = localStorage.getItem("user-preferred-language");
    userLanguage ? changeLanguage(userLanguage) : changeLanguage("en");
    if (Array.isArray(access)) {
      setPathologyFeature(findAuthorizedFeature(access, PATHOLOGY));
      setRequisitionsFeature(findAuthorizedFeature(access, REQUISITIONS));
      setUsersFeature(findAuthorizedFeature(access, USERS));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);

  // console.log("NavMenu authState?.dpodUser", authState?.dpodUser);

  return (
    <header>
      <IdleTimerAlert showModal={showModal} setShowModal={setShowModal} />

      <nav className="navbar fixed-top navbar-expand-md box-shadow-border bg-white py-0">
        <div className="container py-0 navbar-min-height">
          <Link className="py-0 me-3" to="/">
            <img className="logo" src={logo} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {location.pathname !== "/login" && authState?.isAuthenticated && (
            <div
              className="collapse navbar-collapse"
              style={{ justifyContent: "space-between", height: "6rem" }}
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav">
                {/* <li className="nav-item">
                <NavLink className={activeNavItem} to="/overview">
                  Overview
                </NavLink>
              </li> */}
                {isCapabilityAllowed(
                  { api: ORDERS_SEARCH, accessLevel: "POST" },
                  requisitionsFeature?.capabilities
                ) && (
                  <li className="nav-item text-md-center requisition">
                    <NavLink className={activeNavItem} to="/requisitions">
                      {t("navigation.requisitions")}
                    </NavLink>
                  </li>
                )}
                {isCapabilityAllowed(
                  { api: MY_PATHOLOGY_SEARCH, accessLevel: "POST" },
                  pathologyFeature?.capabilities
                ) && (
                  <li className="nav-item text-md-center my-pathology">
                    <NavLink className={activeNavItem} to="/mypathology">
                      {t("navigation.pathologyImages")}
                    </NavLink>
                  </li>
                )}
                {isCapabilityAllowed(
                  { api: GROUPS, accessLevel: "GET" },
                  usersFeature?.capabilities
                ) && (
                  <li className="nav-item text-md-center users">
                    <NavLink className={activeNavItem} to="/users">
                      {t("navigation.users")}
                    </NavLink>
                  </li>
                )}
              </ul>
              <div className="d-flex align-items-center navbar-nav">
                {isCapabilityAllowed(
                  { api: REQUISITION_MY_ITEM, accessLevel: "GET" },
                  requisitionsFeature?.capabilities
                ) && (
                  <div className="navbar-nav">
                    <NavLink className={activeNavItem} to="/cart">
                      <CartIcon />
                    </NavLink>
                  </div>
                )}
                <div className="btn-group d-flex align-items-center ms-5 me-2">
                  <LanguageIcon />
                  <div className="px-2 py-1">{t(language)}</div>
                  <button
                    type="button"
                    className="btn dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    {Object.keys(locales).map((locale, index) => (
                      <li key={index} className="dropdown-item">
                        <button
                          className="btn"
                          onClick={() => changeLanguage(locale)}
                        >
                          {t(locales[locale].title)}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <b className="mx-2 text-primary-blue">
                  {firstName && `${firstName || ""} ${lastName || ""}`}
                </b>
                <button
                  type="button"
                  className="btn logout-button"
                  onClick={loggingOut}
                >
                  {t("logoutModal.logout")}
                </button>
              </div>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default NavMenu;
