import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Root from "./Root";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import "../node_modules/line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const baseUrl = document.baseURI;
const baseUrl = "/";

root.render(
  <BrowserRouter basename={baseUrl}>
    <React.StrictMode>
      <Root />
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
