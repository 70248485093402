import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import SignInWidget from "../Okta/SignInWidget";

const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const location = useLocation();
  const cookies = new Cookies(null, { path: "/" });

  // Clear session storage once per render of login page
  useEffect(() => {
    if (!authState?.isAuthenticated) {
      window.sessionStorage.clear();
      cookies.remove("Session_token");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens, location.state?.path);
  };

  const onError = (error) => {
    console.log("Error logging in", error);
  };

  return (
    <>
      {/* If authenticated redirect to root page */}
      {authState?.isAuthenticated ? (
        <Navigate to="/" replace />
      ) : (
        <SignInWidget config={config} onSuccess={onSuccess} onError={onError} />
      )}
    </>
  );
};

export default Login;
