import { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import React, { lazy } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { FEATURES } from "./Auth/AuthCheck";
import { API_PATHS } from "./Common/Utilities/constants";

import NavMenu from "./Common/NavMenu/NavMenu";
import Landing from "./Components/Landing/Landing";
import Login from "./Components/Login/Login";
import Logout from "./Components/Login/Logout";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";

import "./App.css";
import ErrorAlert from "./Common/ErrorAlert/ErrorAlert";
import LogoutRedirectModal from "./Components/LogoutRedirectModal/LogoutRedirectModal";

// Feature constants
const { PATHOLOGY, REQUISITIONS, USERS, COMMON } = FEATURES;
const {
  ORDERS_SEARCH,
  REQUISITION_MY_ITEM,
  MY_PATHOLOGY_SEARCH,
  USERS_PROFILE,
  GROUPS,
} = API_PATHS;

// Lazy loading components
const MyPathology = lazy(() => import("./Components/MyPathology/MyPathology"));
const Requisitions = lazy(() =>
  import("./Components/Requisitions/Requisitions")
);
const AddRequisitionItem = lazy(() =>
  import("./Components/Requisition/AddRequisitionItem")
);
const RequisitionCart = lazy(() =>
  import("./Components/Requisition/Cart/RequisitionCart")
);
const Overview = lazy(() => import("./Components/Overview/Overview"));
const UserGroups = lazy(() => import("./Components/UserGroups/UserGroups"));

/*
----------------------------- Notes -------------------------------------
1. Applied Okta Sign-in widget with route protection. 
2. Removed `global-cookie` implementation as Okta has built-in token manager.
*/

function App({ oktaAuth, oktaSignInConfig }) {
  const navigate = useNavigate();

  const originalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  /* 
    1. onAuthRequired with customAuthHandler is required when using <SecureRoute> component.
    2. Since the <SecureRoute> isn't yet supported with react-router-dom v6 this can be safely ignored.
  */

  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={originalUri}>
        <NavMenu />
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute
                  routeFeature={COMMON}
                  keyCapability={{
                    api: USERS_PROFILE,
                    accessLevel: "POST",
                  }}
                />
              }
            >
              <Route path="" element={<Landing />} />
            </Route>
            <Route
              path="/login"
              element={<Login config={oktaSignInConfig} />}
            />
            <Route path="/logout" element={<Logout />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route
              path="/overview"
              element={
                <ProtectedRoute
                  routeFeature={COMMON}
                  keyCapability={{
                    api: USERS_PROFILE,
                    accessLevel: "POST",
                  }}
                />
              }
            >
              <Route path="" element={<Overview />} />
            </Route>
            <Route
              path="/mypathology"
              element={
                <ProtectedRoute
                  routeFeature={PATHOLOGY}
                  keyCapability={{
                    api: MY_PATHOLOGY_SEARCH,
                    accessLevel: "POST",
                  }}
                />
              }
            >
              <Route path="" element={<MyPathology />} />
            </Route>
            <Route
              path="/requisitions"
              element={
                <ProtectedRoute
                  routeFeature={REQUISITIONS}
                  keyCapability={{ api: ORDERS_SEARCH, accessLevel: "POST" }}
                />
              }
            >
              <Route path="" element={<Requisitions />} />
            </Route>
            <Route
              path="/addrequisition"
              element={
                <ProtectedRoute
                  routeFeature={REQUISITIONS}
                  keyCapability={{
                    api: REQUISITION_MY_ITEM,
                    accessLevel: "POST",
                  }}
                />
              }
            >
              <Route path="" element={<AddRequisitionItem />} />
            </Route>
            <Route
              path="/cart"
              element={
                <ProtectedRoute
                  routeFeature={REQUISITIONS}
                  keyCapability={{
                    api: REQUISITION_MY_ITEM,
                    accessLevel: "GET",
                  }}
                />
              }
            >
              <Route path="" element={<RequisitionCart />} />
            </Route>
            <Route
              path="/users"
              element={
                <ProtectedRoute
                  routeFeature={USERS}
                  keyCapability={{
                    api: GROUPS,
                    accessLevel: "GET",
                  }}
                />
              }
            >
              <Route path="" element={<UserGroups />} />
            </Route>
          </Routes>
        </main>
        <ErrorAlert />
        <LogoutRedirectModal />
      </Security>
    </div>
  );
}

export default App;
