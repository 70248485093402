import Modal from "bootstrap/js/dist/modal";
import React, { useEffect, useRef, useState } from "react";

const ActionModal = (props) => {
  // Props
  const { children, onClose, classNames, showModal } = props;

  // State variables
  const [modal, setModal] = useState(null);
  const modalRef = useRef();

  // Initiate modal
  useEffect(() => {
    if (!modal) {
      setModal(new Modal(modalRef.current));
    }
  }, [modal]);

  // Show modal
  useEffect(() => {
    if (showModal && modal) {
      modal.show();
    }
  }, [modal, showModal]);

  // Hide modal
  useEffect(() => {
    return () => {
      if (modal) {
        modal.hide();
      }
    };
  }, [modal]);

  return (
    <div
      ref={modalRef}
      className="modal fade"
      tabIndex="-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div className={`modal-dialog ${classNames}`} style={{ height: "auto" }}>
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              id="modal-x"
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <div className="container mb-3">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ActionModal;
