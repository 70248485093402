// Path to be adjusted
async function fetchEnvConfig() {
  try {
    const response = await fetch("/config");
    const fetchedConfig = await response.json();
    return fetchedConfig;
  } catch (error) {
    console.error("Error fetching environment configuration:", error);
    return null;
  }
}

export default fetchEnvConfig;
