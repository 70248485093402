// Pub-Sub module for event based handling

const subscribers = {};

const subscribe = (eventName, callback) => {
  if (!subscribers[eventName]) {
    subscribers[eventName] = [];
  }

  subscribers[eventName].push(callback);
};

const unsubscribe = (eventName, callback) => {
  const eventSubscribers = subscribers[eventName];

  if (eventSubscribers) {
    subscribers[eventName] = eventSubscribers.filter((cb) => cb !== callback);
  }
};

const publish = (eventName, data) => {
  const eventSubscribers = subscribers[eventName];

  if (eventSubscribers) {
    eventSubscribers.forEach((callback) => callback(data));
  }
};

export { publish, subscribe, unsubscribe };
