import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Common/Spinner/Spinner";

const Logout = () => {
  const [backUsed, setBackUsed] = useState(false);
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("popstate", setBackUsed(true));
    if (backUsed) {
      navigate("/login", { replace: "true" });
    } else {
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem("user-preferred-language");
    await oktaAuth.signOut();
  };

  return (
    <div className="my-5">
      <Spinner />
    </div>
  );
};

export default Logout;
