/*
----------------------------- Notes -------------------------------------
1. Removed `global-cookie` implementation as Okta has built-in token manager.
2. Building the headers with auth Bearer token and apiKey
*/
import Cookies from "universal-cookie";
import { ERROR_CODES } from "../Common/Utilities/constants";
import { publish } from "./pubSub";

const handle401 = () => {
  window.location.href = "/logout";
};

const buildHeaders = ({ token, apiKey, handle, ...additionalHeaders }) => {
  const cookies = new Cookies(null, { path: "/" });
  let sessionToken = cookies.get("Session_token");

  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "app-type": "external",
  };
  if (handle) {
    headers.Custlocuserhandle = handle;
  }
  if (additionalHeaders) {
    headers = { ...headers, ...additionalHeaders };
  }
  if (sessionToken) {
    headers.session_token = sessionToken;
  }
  return headers;
};

const fetchData = async (url, options) => {
  try {
    const response = await fetch(`/api/dpodws/v1/${url}`, options);

    // Unauthorized response should only be returned due to expired Okta token and results in a log out
    if (response.status === 401) {
      handle401();
      // Forbidden response should be returned due to user lacking permissions to access endpoint
    } else if (response.status === 403) {
      return response;
      // Empty body success response should be returned by deletes
    } else if (response.status === 204) {
      return response;
    } else if (!response.ok) {
      const errorData = await response.json();
      throw errorData?.ErrorList[0];
    }
    return await response.json();
  } catch (exception) {
    if (exception?.ErrorCode === ERROR_CODES.USER_DELETED) {
      publish("userDeleted", exception?.ErrorMessage);
    } else if (exception?.PasswordResetEmailSentCode === ERROR_CODES.PASS_RESET) {
      publish("userPassReset", exception?.PasswordResetEmailSentMessage);
    } else if (exception?.ErrorMessage) {
      publish("apiError", exception?.ErrorMessage);
    }
    console.log("Failed to fetch", exception);
    return;
  }
};

export const GET = async (url, headers) => {
  const options = {
    method: "GET",
    headers: buildHeaders(headers),
  };

  return await fetchData(url, options);
};

export const POST = async (url, headers, body) => {
  const options = {
    method: "POST",
    headers: buildHeaders(headers),
    body: JSON.stringify(body),
  };

  return await fetchData(url, options);
};

export const PUT = async (url, headers, body) => {
  const options = {
    method: "PUT",
    headers: buildHeaders(headers),
    body: JSON.stringify(body),
  };

  return await fetchData(url, options);
};

export const DELETE = async (url, headers) => {
  const options = {
    method: "DELETE",
    headers: buildHeaders(headers),
  };

  return await fetchData(url, options);
};

export const PATCH = async (url, headers, body) => {
  const options = {
    method: "PATCH",
    headers: buildHeaders(headers),
  };
  if (body) {
    options.body = JSON.stringify(body);
  }

  return await fetchData(url, options);
};
