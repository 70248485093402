import { useDpodUser } from "./useDpodUser";

export const useAuthContext = () => {
  // Custom Hooks
  const authState = useDpodUser();

  // Auth context variables
  const token = authState?.accessToken?.accessToken;
  const userId = authState?.idToken?.claims?.sub;
  const dpodUser = authState?.dpodUser;
  const handle = dpodUser?.handle;

  // ----------------------------- Helper Functions -----------------------------------
  const setDpodUser = (dpodUser) => {
    authState.dpodUser = dpodUser;
  };

  return {
    dpodUser,
    setDpodUser,
    authParams: {
      token,
      handle,
      "Dpod-User-Id": userId,
    },
  };
};
