import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Frame } from "../../Assets/Images/frame.svg";
import { IDLE_TIMER } from "../Utilities/constants";
import ActionModal from "./ActionModal";
import "./Modals.css";

const IdleTimerAlert = ({ timeout = IDLE_TIMER, showModal, setShowModal }) => {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	let idleTimer;

	const onStay = () => {
		setShowModal(false);
	}

	const logout = () => {
		navigate("/logout");
	}

	const resetIdleTimer = () => {
		clearTimeout(idleTimer);
		idleTimer = setTimeout(() => {
			setShowModal(true);

			let counter = 60;
			let interval = setInterval(() => {

				clearTimeout(idleTimer);
				if (--counter === 0) {
					// Logout here
					setShowModal(false);
					clearInterval(interval);
					logout();
				}

				const clearIntervalEvent = () => {
					clearInterval(interval)
				}
				let counterEle = document.querySelector("#counter")
				let modalX = document.querySelector("#modal-x")
				if (counterEle) counterEle.innerHTML = (counter < 10 ? "0" : "") + counter;
				if (modalX) modalX.onclick = clearIntervalEvent;
				let stayBtn = document.querySelector("#btn-stay")
				if (stayBtn) stayBtn.onclick = clearIntervalEvent;
			}, 1000)
		}, timeout);
	};

	const onActivity = (event) => {
		if (event === undefined || event?.view?.location?.pathname === '/login' || event?.target?.location?.pathname === '/login') return;
		resetIdleTimer();
	};

	useEffect(() => {
		onActivity();
		document.onmousemove = onActivity;
		document.onkeydown = onActivity;
		window.addEventListener('message', onActivity);

		return () => {
			window.removeEventListener('message', onActivity);
			clearTimeout(idleTimer);
		};
	}, []);

	const BodyContent = () => {
		return (
			<>
				<div className="modal-body d-flex flex-wrap justify-content-between">
					<Frame className="col-md-5" />
					<div className="col-md-6 mt-3">
						<h3 className="text-primary-blue">
							{t("logoutModal.idleTimeoutHeading")}
						</h3>
						<p className="text-primary-black mt-5">
							{t("logoutModal.idleTimeoutMsg")} 00:<span id="counter"></span>
						</p>
					</div>
				</div>
			</>
		);
	}


	const FooterContent = () => {
		return (
			<div
				className="modal-footer d-grid gap-2 d-md-flex justify-content-md-end"
				style={{ borderTop: 0, justifyContent: "inherit" }}
			>
				<button
					type="button"
					className="btn btn-outline-primary btn-medium rounded-pill m-0"
					onClick={logout}
				>
					{t("logoutModal.logout")}
				</button>
				<button
					type="button"
					className="btn btn-primary btn-grp btn-wide rounded-pill m-0"
					onClick={onStay}
					id="btn-stay"
				>
					<span className="ms-3">
						{t("logoutModal.stay")}
					</span>
					<i className="las la-lg la-arrow-right"></i>
				</button>
			</div>
		);
	};

	return showModal && <div className="z-index-1056 position-absolute">
		<ActionModal onClose={onStay} classNames={"modal-fullscreen"} showModal={showModal}>
			<BodyContent />
			<FooterContent />
		</ActionModal>
	</div>
}

export default IdleTimerAlert;