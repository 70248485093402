import React from "react";
import { useTranslation } from "react-i18next";
import "./Auth.css";

const NotAuthorized = () => {
  const [t] = useTranslation();
  // Markup.....................................................................

  return (
    <div className="card unauthorized-container">
      <div className="card-body">
        <div className="text-center card-title">
          <h3>{t("common.notAuthorized")}</h3>
        </div>
      </div>
    </div>
  );
};

export default NotAuthorized;
