import { useLayoutEffect, useState } from "react";
import { GET } from "../../Services/io";
import { API_PATHS } from "../Utilities/constants";
import { isEmpty } from "../Utilities/helperFunctions";
import { useAuthContext } from "./useAuthContext";

const { MY_CUSTOMER_ACCESS_GROUP } = API_PATHS;

export const useAccessGroups = () => {
  // State Variables
  const [accessGroups, setAccessGroups] = useState(
    JSON.parse(window.sessionStorage.getItem("userAccessGroups"))
  );

  // Other
  const { authParams } = useAuthContext();

  // ------------------ IO -------------------
  const fetchAccessGroup = async () => {
    const response = await GET(MY_CUSTOMER_ACCESS_GROUP, authParams);
    if (response) {
      let retData = [...response.data.groups, ...response.data.users];
      window.sessionStorage.setItem(
        "userAccessGroups",
        JSON.stringify(retData)
      );
      setAccessGroups(retData);
    }
  };

  const updateAccessGroups = (newData) => {
    window.sessionStorage.setItem("userAccessGroups", JSON.stringify(newData));
  };

  const createGroupObj = (newGroupData) => {
    return {
      groupName: newGroupData.name,
      handle: newGroupData.handle,
    };
  };

  const createUserObj = (newUserData) => {
    return {
      name: `${newUserData.firstName} ${newUserData.lastName}`,
      email: newUserData.email,
      handle: newUserData.handle,
      userName: newUserData.userId,
    };
  };

  // Hooks
  useLayoutEffect(() => {
    // Fetch access groups
    if (isEmpty(accessGroups)) {
      fetchAccessGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    accessGroups,
    fetchAccessGroup,
    updateAccessGroups,
    createGroupObj,
    createUserObj,
  };
};
