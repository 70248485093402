import { useOktaAuth } from "@okta/okta-react";
import { useLayoutEffect, useState } from "react";
import { POST } from "../../Services/io";
import { API_PATHS } from "../Utilities/constants";

const { USERS_PROFILE } = API_PATHS;

export const useDpodUser = () => {
  // State Variables
  const [, setHasDpodUser] = useState(false);

  // Custom Hooks
  const { authState } = useOktaAuth();

  const accessToken = authState?.accessToken?.accessToken;
  const claims = authState?.idToken?.claims;
  const dpodUser = authState?.dpodUser;

  // ------------------ IO -------------------
  const fetchDpodUser = async (oktaUserId, token) => {
    const response = await POST(
      USERS_PROFILE,
      {
        token,
        apiKey: true,
        "Dpod-User-Id": oktaUserId,
      },
      {}
    );
    // Setting user data with exception handling
    authState.dpodUser = response?.data || {};
    setHasDpodUser(true);
  };

  // Hooks
  useLayoutEffect(() => {
    // Fetch user profile once post authentication
    if (accessToken && claims && !dpodUser) {
      setHasDpodUser(false);
      fetchDpodUser(claims.sub, accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, claims, dpodUser]);

  return authState;
};
